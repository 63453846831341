<template>
    <form @submit.prevent="onSave" class="relative flex flex-col flex-1 h-full p-6 w-full">
        <header class="general-dashboard-header space-y-1 py-2 px-4 bg-blue-700 rounded sticky">
            <div class="flex items-center">
                <button class="primary-button">
                    <span v-if="saving" class="flex items-center">
                        <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Saving</translate> ...
                    </span>
                    <span v-else>Save</span>
                </button>
            </div>
        </header>


        <autralis-success-comp :show="saved" text="The company updated successfully."/>
        <autralis-error-comp :show="!loading && (loadingError !== '' || savingError !== '')" :text="errorMessage"/>
        <autralis-info-comp :show="noChangesMessage !== null" :text="noChangesMessage"/>


        <div class="mt-6 flex w-full space-x-6">
            <div class="w-full border-0 md:border border-gray-200 rounded pb-10 md:pt-10 px-0 md:px-6 lg:px-16 xl:px-28" style="max-width: 1024px;">
                <div class="text-left mb-9">
                    <h2 class="text-2xl md:text-3xl lg:text-4xl font-extrabold tracking-tight text-gray-900">
                        General info
                    </h2>
                    <p class="mt-1 text-base md:text-lg leading-6 text-gray-500">
                        Edit general information of your company.
                    </p>
                </div>

                <autralis-loading-comp v-if="loading" size="md"/>


                <div v-else>
                    <autralis-input-group-comp label="Company name"
                                               :error="formErrors.name"
                                               :value="company.name"
                                               required
                                               placeholder="Autralis"
                                               @on-change="company.name = $event"/>

                    <div class="flex flex-col xl:flex-row space-x-0 xl:space-x-6 mt-6">
                        <div class="w-full xl:w-1/2 mb-6 xl:mb-0">
                            <autralis-input-group-comp label="Vat number"
                                                       :error="formErrors.vatNumber"
                                                       placeholder="BE0644.111.222"
                                                       required
                                                       @on-change="company.vatNumber = $event"
                                                       :value="company.vatNumber"/>
                        </div>
                        <div class="w-full xl:w-1/2">
                            <autralis-countries-select-comp label='Country'
                                                            :selected="company.country"
                                                            required
                                                            @on-country-change="company.country = $event"/>
                        </div>
                    </div>


                    <div class="mt-6">
                        <autralis-input-group-comp type="email"
                                                   label="Email address"
                                                   :error="formErrors.email"
                                                   required
                                                   :value="company.email"
                                                   placeholder="john.doe@gmail.com"
                                                   @on-change="company.email = $event"/>
                    </div>

                    <div class="flex flex-col xl:flex-row space-x-0 xl:space-x-6 mt-6">
                        <div class="w-full xl:w-1/2 mb-6 xl:mb-0">
                            <autralis-input-group-comp label="Telephone"
                                                       placeholder="+32 9 230 02 33"
                                                       :value="company.phone"
                                                       @on-change="company.phone = $event"/>
                        </div>
                        <div class="w-full xl:w-1/2">
                            <autralis-input-group-comp label="Mobile"
                                                       placeholder="+32 486 12 34 56"
                                                       :value="company.mobile"
                                                       @on-change="company.mobile = $event"/>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </form>
</template>

<script>
import axios from "axios";
import Loading from "@/components/loading";
import ErrorBox from "@/components/error-box";
import SuccessBox from "@/components/success-box";
import InfoBox from "@/components/info-box";
import InputGroup from '@/components/input-group'
import CountriesSelect from "@/components/countries-select";
import {isValidEmail, isValidEUVAT} from "../../../../../common";
import {isEqual} from "lodash";
import {INFO_BOX_TIMEOUT} from "../../../../constants";

export default {
    name: "GeneralDashboard",
    data() {
        return {
            loading: false,
            loadingError: '',
            company: {},
            initCompany: {},
            formErrors: {},
            saving: false,
            savingError: '',
            saved: false,
            noChangesMessage: null
        }
    },

    computed: {
        saveDisabled: {
            cache: false,
            get: function () {
                return !this.hasChanges || !this.validateForm().isValid
            }
        },
        hasChanges() {
            return !isEqual(this.company, this.initCompany)
        },
        errorMessage() {
            let message = ''
            if (this.loadingError !== '') message = this.loadingError;
            if (this.savingError !== '') message = this.savingError;
            return message
        }
    },

    methods: {
        async useGet(endpoint, onErrorCb, onSuccessCb) {
            try {
                this.loading = true;
                this.loadingError = '';
                const response = await axios.get(endpoint, {withCredentials: true})
                response.data.result === 'Ok' ? onSuccessCb(response.data) : onErrorCb()
                this.loading = false

            } catch (err) {
                onErrorCb()
                this.loading = false
            }
        },

        async usePost(endpoint, onErrorCb, onSuccessCb, data = {}) {
            try {
                this.saving = true;
                this.savingError = '';
                const response = await axios.post(endpoint, data, {withCredentials: true})
                response.data.result === 'Ok' ? onSuccessCb(response.data) : onErrorCb()
                this.saving = false

            } catch (err) {
                onErrorCb()
                this.saving = false
            }
        },

        async handleFetchCompany() {
            await this.useGet(
                '/manager/expert3/xp3/company-info/',
                () => this.loadingError = 'Loading company info failed. Please try again.',
                data => {
                    this.company = {...data.company}
                    this.initCompany = {...data.company}
                }
            );
        },

        updateCountry(country) {
            this.company = {...this.company, country}
        },

        validateForm() {
            let isValid = true;
            let errors = {};

            if (!this.company.name) {
                errors['name'] = "The company name cannot be empty.";
                isValid = false;
            }
            if (!isValidEmail(this.company.email)) {
                errors['email'] = "The format of the email address is not correct.";
                isValid = false
            }

            if (!isValidEUVAT(this.company.vatNumber)) {
                errors['vatNumber'] = "The format of the VAT number is not correct.";
                isValid = false
            }

            return {
                isValid,
                errors
            }
        },

        isValid() {
            this.formErrors = {};
            const {isValid, errors} = this.validateForm();
            this.formErrors = {...errors}
            return isValid
        },

        async onSave() {
            if (this.isValid()) {
                if (this.hasChanges) {
                    await this.usePost(
                        '/manager/expert3/xp3/update-company/' + this.company.id + "/",
                        () => this.savingError = 'Update company info failed. Please try again.',
                        () => {
                            this.saved = true;
                            this.initCompany = {...this.company}
                            setTimeout(() => this.saved = false, INFO_BOX_TIMEOUT)
                        },
                        this.company
                    );
                } else {
                    this.noChangesMessage = 'Nothing changed.';
                    setTimeout(() => this.noChangesMessage = null, INFO_BOX_TIMEOUT)
                }
            }
        }
    },

    components: {
        'autralis-loading-comp': Loading,
        'autralis-error-comp': ErrorBox,
        'autralis-info-comp': InfoBox,
        'autralis-success-comp': SuccessBox,
        'autralis-input-group-comp': InputGroup,
        'autralis-countries-select-comp': CountriesSelect,
    },

    created() {
        this.handleFetchCompany();
    }
}
</script>

<style scoped>
.general-dashboard-header {
    top: 0;
    z-index: 10
}

@media (max-width: 1023px) {
    .general-dashboard-header {
        top: 157px;
    }
}
</style>